exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualites-js": () => import("./../../../src/pages/actualites.js" /* webpackChunkName: "component---src-pages-actualites-js" */),
  "component---src-pages-histoire-js": () => import("./../../../src/pages/histoire.js" /* webpackChunkName: "component---src-pages-histoire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscription-js": () => import("./../../../src/pages/inscription.js" /* webpackChunkName: "component---src-pages-inscription-js" */),
  "component---src-pages-minibasket-js": () => import("./../../../src/pages/minibasket.js" /* webpackChunkName: "component---src-pages-minibasket-js" */),
  "component---src-pages-organigramme-js": () => import("./../../../src/pages/organigramme.js" /* webpackChunkName: "component---src-pages-organigramme-js" */),
  "component---src-pages-partenaires-js": () => import("./../../../src/pages/partenaires.js" /* webpackChunkName: "component---src-pages-partenaires-js" */),
  "component---src-pages-permanences-js": () => import("./../../../src/pages/permanences.js" /* webpackChunkName: "component---src-pages-permanences-js" */),
  "component---src-pages-planning-js": () => import("./../../../src/pages/planning.js" /* webpackChunkName: "component---src-pages-planning-js" */),
  "component---src-pages-salles-js": () => import("./../../../src/pages/salles.js" /* webpackChunkName: "component---src-pages-salles-js" */),
  "component---src-pages-sectionsportive-js": () => import("./../../../src/pages/sectionsportive.js" /* webpackChunkName: "component---src-pages-sectionsportive-js" */),
  "component---src-templates-news-page-js": () => import("./../../../src/templates/NewsPage.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/TeamPage.js" /* webpackChunkName: "component---src-templates-team-page-js" */)
}

